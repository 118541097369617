<template>
  <div id="vehicle" @click="blur">
    <AppContainer :useBackground="true">
      <div class="text-center">
        <div class="profile-edit-eight position-relative">
          <div class="position-absolute w-100">
            <div class="text-left" @click="$emit('back-tutorial')">
              <img
                class="btn-back"
                :src="require('@/assets/image/arrow-back.svg')"
                alt=""
              />
            </div>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(nextTutorial)">
                <label for="" class="text-center f-maru"
                  >お車の有無と車種を教えてください！</label
                >
                <ValidationProvider name="車" rules="" v-slot="{ errors }">
                  <div
                    class="position-relative vehicle m-auto focus-div"
                    id="vehicle-men"
                  >
                    <div class="profile-edit-eight__input m-auto">
                      <div
                        class="profile-edit-eight__input-label text-left f-maru"
                      >
                        車
                      </div>
                      <div
                        class="
                          profile-edit-eight__input-select
                          position-relative
                        "
                        :class="isShowListCar ? 'active' : ''"
                        @click="showListCar"
                      >
                        <div
                          class="
                            f-maru
                            input
                            d-flex
                            align-items-center
                            justify-content-start
                          "
                        >
                          {{ carSelected.text }}
                        </div>
                      </div>
                    </div>
                    <transition name="slide">
                      <ul
                        class="profile-edit-eight__listCar text-left"
                        v-if="isShowListCar"
                      >
                        <li
                          class="profile-edit-eight__listCar-item f-maru"
                          v-for="(item, index) in listCar"
                          :key="index"
                          @click="selectOptionCar(item)"
                        >
                          {{ item.text }}
                        </li>
                      </ul>
                    </transition>
                  </div>
                  <span v-if="errors[0]" class="error">{{ errors[0] }}</span>
                </ValidationProvider>
                <div
                  class="profile-edit-eight__input m-auto"
                  v-if="carSelected.value == 1"
                >
                  <div class="profile-edit-eight__input-label text-left f-maru">
                    車種
                  </div>
                  <div
                    class="
                      profile-edit-eight__input-type
                      position-relative
                      m-auto
                    "
                  >
                    <ValidationProvider
                      name="車種"
                      rules="max:255"
                      v-slot="{ errors }"
                    >
                      <input
                        type="text"
                        class="f-maru input"
                        v-model="vehicleType"
                      />
                      <span v-if="errors[0]" class="error">{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="btn-button">
                  <button
                    type="submit"
                    class="profile-edit-eight__next position-relative f-maru"
                  >
                    次へ
                    <img
                      src="@/assets/image/arrow-right-submit.svg"
                      class="icon position-absolute"
                      alt=""
                    />
                  </button>
                  <div
                    class="profile-edit-eight__skip f-maru"
                    @click="nextTutorial"
                  >
                    スキップ
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </AppContainer>
  </div>
</template>

<script>
export default {
  name: "ProfileEditScreenDetailsEight",
  data() {
    return {
      carSelected: "",
      isShowListCar: false,
      listCar: [
        { text: "あり", value: 1 },
        { text: "なし", value: 0 }
      ],
      vehicleType: ""
    };
  },
  props: {
    tabindex: {
      type: Number,
      require: null,
      default: 0
    }
  },
  watch: {
    carSelected: function() {
      this.$emit("showProfileCar", this.carSelected);
    },
    vehicleType: function() {
      this.$emit("showProfileVehicleType", this.vehicleType);
    }
  },
  methods: {
    blur(e) {
      if (!e.target.closest("#vehicle-men")) {
        this.isShowListCar = false;
      }
    },
    nextTutorial() {
      this.$emit("tutorialVehicle");
    },
    showListCar() {
      this.isShowListCar = !this.isShowListCar;
      this.$nextTick(() => {
        let listOptions = document.querySelectorAll(
          ".profile-edit-eight__listCar-item"
        );
        for (let i = 0; i < listOptions.length; i++) {
          if (listOptions[i].innerText == this.carSelected.text) {
            listOptions[i].style.backgroundColor = "#e5e5e5";
            listOptions[i].style.color = "#000000";
          }
        }
      });
    },
    selectOptionCar(item) {
      if (this.carSelected == item) {
        this.carSelected = "";
        this.isShowListCar = !this.isShowListCar;
        return;
      }
      this.carSelected = item;
      this.isShowListCar = !this.isShowListCar;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/men/tutorial/tutorialVehicle.scss";
#vehicle {
  /deep/ .app-body._header .app-container__content {
    padding: 0;
    background-color: #ffffff;
  }
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 100vh;
  .slide-enter,
  .slide-leave-to {
    transform: scaleY(0);
  }
  .focus-div {
    outline: none;
    box-shadow: none;
  }
  .btn-button {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 442.66px;
  }
  @media screen and (min-width: 1079px) {
    .btn-button {
      top: 648px;
    }
  }
}
</style>
