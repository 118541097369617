<template>
  <div id="edit-profile">
    <ModalLayout ref="modalProfile">
      <p class="modal-logout-title">
        登録した情報が保存されません。それでもよろしいですか。
      </p>
      <div class="d-flex justify-content-center">
        <button
          @click.prevent="
            $router.push({ name: 'MenMyPagePersonalInformationEdit' })
          "
          class="btn-cancel-logout"
        >
          はい
        </button>
        <button @click="$refs.modalProfile.closeModal()" class="btn-logout">
          いいえ
        </button>
      </div>
    </ModalLayout>
    <div class="edit-profile-vue-cropper" v-if="isShowCropper">
      <Cropper
        class="upload-example-cropper"
        :src="imageTest"
        @change="onChange"
        ref="cropper"
        :stencil-props="{
          aspectRatio: aspectRatio1
        }"
        :stencil-size="stencilSize"
        imageRestriction="stencil"
      />
      <div class="d-flex btn-option justify-content-center">
        <div class="f-w3 mr-3 btn-cancel">
          <button @click="cancel()" class="btn">キャンセル</button>
        </div>
        <div class="f-w3 btn-success">
          <button @click="down()" class="btn">登録する</button>
        </div>
      </div>
    </div>
    <ModalLayout ref="modal-image-profile">
      <ImageProfile
        :propsUser="propsUser"
        @acceptModal="acceptModal"
        @closeModal="closeModal"
      />
    </ModalLayout>
    <ModalLayout ref="modal-image-profile-extra">
      <ImageProfileExtra
        :propsUser="propsUser"
        @acceptModal="acceptModal"
        @closeModal="closeModal"
      />
    </ModalLayout>
    <div class="tutorial" v-if="step"></div>
    <!--    <div v-if="invite" class="add-friend">-->

    <!--    </div>-->
    <tutorial-notification-image
      v-if="step == 2"
      @createImage="createImage($event)"
      @back-tutorial="backTutorial"
    />
    <!--    <tutorial-add-image-->
    <!--      v-if="step == 5"-->
    <!--      @addImage="addImage()"-->
    <!--      @back-tutorial="backTutorial"-->
    <!--    />-->

    <InviteFriendLine
      v-if="step == 3"
      @addFriendPrego="addFriendPrego"
      @resetAddFriendPrego="resetAddFriendPrego"
    />
    <!--    <tutorial-phone-->
    <!--      v-if="step == 6"-->
    <!--      @tutorialPhone="tutorialPhone($event)"-->
    <!--      @back-tutorial="backTutorial"-->
    <!--    />-->
    <!--    <tutorial-nickname-->
    <!--      @getNickname="getNickname($event)"-->
    <!--      v-if="step == 3"-->
    <!--      @back-tutorial="backTutorial"-->
    <!--      @tutorialNickname="tutorialNickname($event)"-->
    <!--    />-->
    <!--    <tutorial-edit-->
    <!--      v-if="step == 5 && showTutorial"-->
    <!--      @tutorialEdit="tutorialEdit($event)"-->
    <!--      @getDate="getDate($event)"-->
    <!--      @back-tutorial="backTutorial"-->
    <!--      @date="date($event)"-->
    <!--    />-->
    <!-- <ModalLayout :showModal="true"> -->
    <tutorial-basic-info
      v-if="step == 1"
      @back-tutorial="backTutorial"
      @tutorialBasicInfo="tutorialBasicInfo($event)"
      @getAddress="getAddress($event)"
      @bestScore="getBestScore($event)"
      @avgScore="getAvgScore($event)"
      :propsSex="user.sex"
      @getNickname="getNickname($event)"
      @getDate="getDate($event)"
      @date="date($event)"
    />
    <!-- </ModalLayout> -->
    <!--    <tutorial-point-->
    <!--      v-if="step == 7"-->
    <!--      @tutorialPoint="tutorialPoint($event)"-->
    <!--      @bestScore="getBestScore($event)"-->
    <!--      @avgScore="getAvgScore($event)"-->
    <!--      @back-tutorial="backTutorial"-->
    <!--    />-->
    <!--    <tutorial-law-->
    <!--      v-if="step == 2"-->
    <!--      @nextStepWelcome="tutorialLaw()"-->
    <!--      :propsSex="user.sex"-->
    <!--      @changeStep="backTutorial"-->
    <!--      @back-tutorial="backTutorial"-->
    <!--    />-->
    <tutorial-edit-complete
      v-if="step == 4"
      @tutorialEditComplete="tutorialEditComplete()"
      @back-tutorial="backTutorial"
    />
    <career-tutorial
      v-if="step == 11"
      @tutorialCareer="tutorialCareer($event)"
      @emitReflect="reflectData"
      :propsOptions="commonTutorials.occupations"
      @back-tutorial="backTutorial"
    />
    <history-tutorial
      v-if="step == 12"
      @tutorialHistory="tutorialHistory($event)"
      @emitReflect="reflectData"
      :propsOptions="commonTutorials.golfExperiences"
      @back-tutorial="backTutorial"
    />
    <area-tutorial
      v-if="step == 13"
      @tutorialArea="tutorialArea($event)"
      @emitReflect="reflectData"
      :propsOptions="commonTutorials.golfAreas"
      @back-tutorial="backTutorial"
    />
    <participate-tutorial
      v-if="step == 14"
      @tutorialParticipate="tutorialParticipate($event)"
      @back-tutorial="backTutorial"
      @emitReflect="reflectData"
    />
    <tutorial-number-of-round
      v-if="step == 15"
      @submitNumberOfRound="tutorialNumberOfRound($event)"
      @nextNumberOfRound="nextTutorialNumberOfRound()"
      @emitReflect="reflectData"
      :propsOptions="commonTutorials.golfFrequencies"
      @back-tutorial="backTutorial"
    />
    <tutorial-tobacco
      v-if="step == 16"
      @submitTobacco="tutorialTobacco($event)"
      @nextTobacco="nextTutorialTobacco()"
      :propsOptions="commonTutorials.cigarettes"
      @back-tutorial="backTutorial"
    />
    <tutorial-sake
      v-if="step == 17"
      @nextSake="nextTutorialSake()"
      @submitSake="tutorialSake($event)"
      :propsOptions="commonTutorials.alcohols"
      @emitReflect="reflectData"
      @back-tutorial="backTutorial"
    />
    <tutorial-annual-in-come
      v-if="step == 18"
      @nextAnnualIncome="nextTutorialAnnualIncome()"
      @submitAnnualIncome="tutorialAnnualIncome($event)"
      @emitReflect="reflectData"
      :propsOptions="commonTutorials.annualIncomes"
      @back-tutorial="backTutorial"
    />
    <tutorialVehicle-body
      v-if="step == 19"
      @tutorialVehicle="tutorialVehicle()"
      @showProfileCar="showProfileCar"
      @showProfileVehicleType="showProfileVehicleType"
      @back-tutorial="backTutorial"
    />
    <tutorialGolfMember-body
      v-if="step == 20"
      @tutorialGolfMember="tutorialGolfMember()"
      @showMember="showMember"
      @showGolfCourse="showGolfCourse"
      @back-tutorial="backTutorial"
    />
    <tutorialDating-body
      v-if="step == 21"
      @tutorialDating="tutorialDating()"
      @showDatingStatus="showDatingStatus"
      :relationship="commonTutorials.relationships"
      @back-tutorial="backTutorial"
    />
    <tutorialComplete-body
      v-if="step == 22"
      @tutorialComplete="tutorialComplete()"
      @back-tutorial="backTutorial"
    />
    <template v-if="isLoading">
      <AppLoading />
    </template>
    <template v-else>
      <div class="edit-profile" v-if="!step">
        <card-header
          ref="header"
          :node="headerTitle"
          @back-tutorial="backTutorial"
        />
        <div id="content-scroll" class="content-scroll">
          <div
            class="edit-profile__content"
            style="position: relative; z-index: 0"
            id="edit-profile__content"
          >
            <div class="avatar m-auto" v-if="step">
              <div class="avatar__div--tutorial">
                <label>
                  <b-form-file
                    class="d-none"
                    accept="image/jpeg, image/png, image/jpg"
                  />
                  <img
                    src="@/assets/image/image-icon.svg"
                    v-if="!image"
                    class="icon"
                  />
                  <img
                    class="image position-absolute"
                    v-if="image != null"
                    :src="image"
                    alt="your image"
                  />
                  <div class="panel-border">
                    <div class="panel--white">
                      <div class="panel--white__div">
                        <div class="panel">
                          <div class="panel__div">
                            <div class="title d-flex">
                              <p class="title__p">BEST SCORE</p>
                            </div>
                            <div
                              class="
                                average
                                d-flex
                                justify-content-between
                                align-items-center
                              "
                            >
                              <div
                                class="average__div average__div--left"
                              ></div>
                              <span class="average__span f-w3">AVERAGE</span>
                              <div class="average__div"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div class="content" v-else>
              <div class="avatar d-flex justify-content-center">
                <div class="avatar__div avatar__div--left f-w3">
                  <div class="avatar__div--right" id="avatar__div--left">
                    <label>
                      <b-form-file
                        class="d-none"
                        accept="image/jpeg, image/png, image/jpg"
                        ref="avatar-left"
                        v-model="listImageModel[0].image"
                        @change="chooseImage($event, 0, true)"
                      />
                      <!-- <img
                        src="@/assets/image/image-icon.svg"
                        v-if="listImage[0].image_url == null"
                        class="icon"
                      /> -->
                      <template v-if="listImage[0].image_url != null">
                        <img
                          src="@/assets/image/icon_profile/icon-plus.svg"
                          class="icon"
                          v-if="!isShowCropper"
                        />
                        <img class="image" :src="listImage[0].image_url" />
                      </template>
                      <img
                        src="@/assets/image/image-icon.svg"
                        v-else
                        class="icon top"
                      />
                      <div class="panel-border position-absolute">
                        <div
                          class="
                            position-relative
                            w-100
                            h-100
                            d-flex
                            justify-content-center
                          "
                        >
                          <div
                            v-if="userDetail.male_prego_rank"
                            class="golf-avatar"
                            :class="{
                              'golf-avatar-white':
                                userDetail.male_prego_rank == 'WHITE' ||
                                userDetail.male_prego_rank == null,
                              'golf-avatar-red':
                                userDetail.male_prego_rank == 'RED',
                              'golf-avatar-blue':
                                userDetail.male_prego_rank == 'BLUE',
                              'golf-avatar-green':
                                userDetail.male_prego_rank == 'GREEN',
                              'golf-avatar-silver':
                                userDetail.male_prego_rank == 'SILVER',
                              'golf-avatar-gold':
                                userDetail.male_prego_rank == 'GOLD',
                              'golf-avatar-black':
                                userDetail.male_prego_rank == 'BLACK' ||
                                userDetail.male_prego_rank == 'PG'
                            }"
                          />
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <!-- <div class="avatar__div col-6">
                  <div class="avatar__div--right" id="avatar__div--right">
                    <label>
                      <b-form-file
                        class="d-none"
                        ref="avatar-right"
                        v-model="listImageModel[1].image"
                        accept="image/jpeg, image/png, image/jpg"
                        @change="chooseImage($event, 1)"
                      />
                      <img
                        src="@/assets/image/image-icon.svg"
                        v-if="listImage[1].image_url == null"
                        class="icon"
                      />
                      <img
                        class="image position-absolute"
                        :src="listImage[1].image_url"
                        v-else
                      />
                      <div class="param"></div>
                      <div class="img"></div>
                      <div class="best-score-title">
                        <span class="best-score-margin">BEST SCORE</span>
                      </div>
                      <div class="average-title f-w3"><span>AVERAGE</span></div>
                      <div class="border-rectangle-left"></div>
                      <div class="border-rectangle-right"></div>
                    </label>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="image-list d-flex flex-wrap">
              <div
                class="
                  image-list__div-video
                  position-relative
                  d-flex
                  justify-content-center
                  align-items-center
                  flex-column
                "
              >
                <div class="video" v-if="!video">
                  <label
                    class="
                      d-flex
                      justify-content-center
                      align-items-center
                      flex-column
                    "
                  >
                    <input
                      class="d-none"
                      type="file"
                      id="chooseVideo"
                      accept=".mov,.mp4"
                      @change="chooseVideo"
                      :key="uploadVideo"
                    />
                    <div v-if="!video">
                      <img
                        src="@/assets/image/video.svg"
                        class="image-icon"
                        alt=""
                      />
                      <div class="f-w3 video-time">15s</div>
                    </div>
                  </label>
                </div>
                <video
                  id="video"
                  width="100%"
                  height="100%"
                  class="video-upload"
                  :class="video ? 'active' : ''"
                  controlslist="nodownload"
                  :poster="poster"
                  preload="metadata"
                  :src="video"
                  type="video/*"
                  @click="play()"
                ></video>
                <img
                  src="@/assets/image/icon_profile/icon-play-video.svg"
                  @click="play()"
                  alt=""
                  v-if="isShowIconPlayVideo"
                  class="play-video active"
                />
                <div @click="deleteVideo()" class="video-delete">
                  <div class="icon-delete" v-if="video">
                    <img
                      src="@/assets/image/icon_profile/icon-close.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div
                class="
                  image-list__div
                  d-flex
                  justify-content-center
                  align-items-center
                "
                v-for="(picture, index) in listPictures"
                :key="index"
              >
                <div class="image">
                  <label
                    class="d-flex justify-content-center align-items-center"
                  >
                    <b-form-file
                      class="d-none"
                      v-model="listImageModel[index + 2].image"
                      accept="image/jpeg, image/png, image/jpg"
                      @change="chooseImage($event, index + 2, true)"
                    />
                    <img
                      src="@/assets/image/image-icon.svg"
                      v-if="listImage[index + 2].image_url == null"
                      class="image__icon"
                    />
                    <img
                      v-else
                      class="image"
                      :src="listImage[index + 2].image_url"
                    />
                  </label>
                  <div
                    @click="deleteImage(index + 2)"
                    class="video-delete"
                    v-if="listImage[index + 2].image_url"
                  >
                    <div class="icon-delete">
                      <img
                        src="@/assets/image/icon_profile/icon-close.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text" v-if="userDetail">
              <p class="f-w6 text-left text--title">現在のランク</p>
              <div class="d-flex justify-content-between">
                <p class="f-w3 text--left">ゴルフレベル</p>
                <p class="f-w6 text--right">{{ male_golf_rank }}</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="f-w3 text--left">ランク</p>
                <p class="f-w6 text--right">{{ male_prego_rank }}</p>
              </div>
            </div>
            <div class="text-center fill-profile f-w6">
              プロフィールを埋めて、
            </div>
            <div class="text-center fill-profile f-w6">
              オファーをスムーズにしましょう！
            </div>
            <hr />
            <div class="d-flex justify-content-center f-w6">
              <button class="info-btn" @click="$refs.modalProfile.openModal()">
                <img src="@/assets/image/icon_profile/icon-info.svg" alt="" />
                個人情報
              </button>
            </div>
            <div class="info" v-if="user && userDetail">
              <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                <form
                  @submit.prevent="handleSubmit(onSubmit)"
                  class="form text-left f-w6"
                >
                  <b-row ref="nickname">
                    <b-col class="col-6">
                      <p>
                        ニックネーム
                        <span>*</span>
                      </p>

                      <ValidationProvider
                        name="ニックネーム"
                        rules="required|max:8"
                        v-slot="{ errors }"
                      >
                        <input
                          type="text"
                          class="f-w3 option-input"
                          id="nickname"
                          ref="target-nickname"
                          v-model="nickname"
                          @blur="handleBlur()"
                          @keyup="changeNickName"
                        />
                        <span class="error f-w3">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>
                    <b-col class="col-6">
                      <p>交際</p>
                      <SearchProfile
                        v-model="relationship"
                        :options="commonTutorials.relationships"
                        :obligatory="true"
                        @select-option="relationship = $event"
                      />
                    </b-col>
                  </b-row>
                  <b-row ref="career">
                    <b-col class="col-6">
                      <p>職業</p>
                      <SearchProfile
                        v-model="occupation"
                        :options="commonTutorials.occupations"
                        :obligatory="true"
                        @select-option="occupation = $event"
                      />
                    </b-col>
                    <b-col class="col-6">
                      <p>身長</p>
                      <SearchProfile
                        v-model="user_height"
                        :options="heightOptions"
                        :obligatory="true"
                        @select-option="user_height = $event"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="position-relative">
                    <b-col class="col">
                      <p>自己紹介 <span>*</span></p>
                      <ValidationProvider
                        name="自己紹介"
                        rules="required|max:10000"
                        v-slot="{ errors }"
                      >
                        <b-form-textarea
                          class="f-w3 self-introduce"
                          id="user_biography"
                          v-model="user_biography"
                          @blur="handleBlur()"
                          @keyup="changeBiography"
                          no-resize
                        />
                        <span class="error f-w3">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>
                    <div
                      v-if="!user_biography"
                      class="position-absolute place-holder"
                      @click="focusTextArea()"
                    >
                      初めまして！こんにちは♪楽しくラウンド出来たらうれしいです♪
                      <br />
                      ゴルフのお誘いメッセージお待ちしてます！
                    </div>
                  </b-row>
                  <div
                    class="
                      horizontal-line
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                  >
                    <div class="horizontal-line__div" />
                    <span class="f-w3 text-center">基本情報</span>
                    <div class="horizontal-line__div" />
                  </div>
                  <b-row ref="post-code">
                    <b-col>
                      <p>郵便番号 <span>*</span></p>
                      <ValidationProvider
                        name="郵便番号"
                        rules="required|regex:^\d{3}-\d{4}$"
                        v-slot="{ errors }"
                      >
                        <div class="d-flex">
                          <input
                            v-model="postCode"
                            v-on:keydown="checkPostCode"
                            v-on:blur="handleBlurPostCode"
                          />
                          <input
                            class="border-input"
                            type="button"
                            value="住所検索"
                            @click="filterPostCode"
                          />
                        </div>
                        <span class="error f-w3">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row ref="prefecture">
                    <b-col>
                      <p>都道府県 <span>*</span></p>
                      <ValidationProvider
                        name="都道府県"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <input v-model="userDetail.prefecture" readonly />
                        <span class="error f-w3">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row ref="address">
                    <b-col>
                      <p>市区町村 <span>*</span></p>
                      <ValidationProvider
                        name="市区町村"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <input v-model="userDetail.residence" readonly />
                        <span class="error f-w3">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row ref="address-area">
                    <b-col>
                      <p>町域</p>
                      <ValidationProvider
                        name="町域"
                        rules=""
                        v-slot="{ errors }"
                      >
                        <input v-model="area" readonly />
                        <span class="error f-w3">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row ref="area-detail">
                    <b-col>
                      <p>丁目 <span>*</span></p>
                      <ValidationProvider
                        name="丁目"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <SearchProfile
                          v-model="areaDetail"
                          :options="listAreaDetail"
                          :obligatory="true"
                          @select-option="areaDetail = $event"
                        />
                        <span class="error f-w3">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row ref="street-name">
                    <b-col>
                      <p>番地 <span>*</span></p>
                      <ValidationProvider
                        name="番地"
                        rules="required|max:255"
                        v-slot="{ errors }"
                      >
                        <div class="d-flex">
                          <input v-model="userDetail.street_name" />
                        </div>
                        <span class="error f-w3">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row ref="house-name">
                    <b-col>
                      <p>マンション、アパート名</p>
                      <ValidationProvider
                        name="マンション、アパート名"
                        rules="max:255"
                        v-slot="{ errors }"
                      >
                        <div class="d-flex">
                          <input v-model="userDetail.house_name" />
                        </div>
                        <span class="error f-w3">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row
                    ref="point"
                    v-if="
                      user.male_golf_rank_id == 1 ||
                        user.male_golf_rank_id == null
                    "
                  >
                    <b-col>
                      <p>ベストスコア <span>*</span></p>
                      <ValidationProvider
                        name="ベストスコア"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <SearchProfile
                          v-model="best_score"
                          :options="bestScoreOptions"
                          @select-option="best_score = $event"
                        />
                        <span class="error f-w3">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>
                    <b-col>
                      <p>アベレージスコア <span>*</span></p>
                      <ValidationProvider
                        name="アベレージスコア"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <SearchProfile
                          v-model="avg_score"
                          :options="avgScoreOptions"
                          @select-option="avg_score = $event"
                        />
                        <span class="error f-w3">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row ref="history">
                    <b-col>
                      <p>ゴルフ歴</p>
                      <SearchProfile
                        v-model="golf_experience"
                        :options="commonTutorials.golfExperiences"
                        :obligatory="true"
                        @select-option="golf_experience = $event"
                      />
                    </b-col>
                  </b-row>
                  <b-row ref="NOR">
                    <b-col>
                      <p>年間ラウンド数</p>
                      <SearchProfile
                        v-model="golf_frequency"
                        :options="commonTutorials.golfFrequencies"
                        :obligatory="true"
                        @select-option="golf_frequency = $event"
                      />
                    </b-col>
                  </b-row>
                  <b-row ref="area">
                    <b-col>
                      <p>プレーエリア <span>*</span></p>
                      <ValidationProvider
                        name="プレーエリア"
                        :rules="{
                          required: true,
                          'option-min': 2
                        }"
                        v-slot="{ errors }"
                      >
                        <SelectMultiFilterCast
                          :options="commonTutorials.golfAreas"
                          v-model="areaValue"
                          @select-option="areaValue = $event"
                        />
                        <span class="error f-w3">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row ref="participate">
                    <b-col>
                      <p>希望合流方法 <span>*</span></p>
                      <ValidationProvider
                        name="希望合流方法"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <SearchProfile
                          v-model="method"
                          :options="methodOptions"
                          @select-option="method = $event"
                        />
                        <span class="error f-w3">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <p ref="target-vehicle">車</p>
                      <SearchProfile
                        v-model="car"
                        :options="carOptions"
                        :obligatory="true"
                        @select-option="car = $event"
                      />
                    </b-col>
                  </b-row>
                  <b-row v-if="car == 0" class="vehicle-type">
                    <b-col>
                      <ValidationProvider
                        name="車種"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <b-form-textarea
                          type="text"
                          id="car_model"
                          v-model="car_model"
                          @blur="handleBlur()"
                          class="f-w3"
                          placeholder="こちらに車種を入力してください。"
                          no-resize
                        />
                        <span class="error f-w3">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row ref="member">
                    <b-col>
                      <p>ゴルフ会員権</p>
                      <SearchProfile
                        v-model="golf_member"
                        :options="golfMemberOptions"
                        :obligatory="true"
                        @select-option="golf_member = $event"
                      />
                    </b-col>
                  </b-row>
                  <b-row v-if="golf_member == 0" class="vehicle-type">
                    <b-col>
                      <ValidationProvider
                        name="ゴルフ場"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <b-form-textarea
                          type="text"
                          id="golf_course"
                          v-model="golf_course"
                          @blur="handleBlur()"
                          class="f-w3"
                          placeholder="ゴルフ会員権を保有されているゴルフ場を入力ください。"
                          no-resize
                        />
                        <span class="error f-w3">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <p ref="target-tobacco" class="tobacco">タバコ</p>
                  <b-row class="f-w3">
                    <input
                      v-for="(cigarette, index) in commonTutorials.cigarettes"
                      :key="index"
                      type="button"
                      :value="cigarette.name"
                      class="input__btn"
                      :class="isSmoke == cigarette.cigarette_id ? 'choose' : ''"
                      @click="smoke(cigarette.cigarette_id)"
                    />
                  </b-row>
                  <p ref="sake" class="sake">お酒</p>
                  <b-row class="f-w3">
                    <input
                      v-for="(alcohol, index) in commonTutorials.alcohols"
                      :key="index"
                      type="button"
                      :value="alcohol.name"
                      class="input__btn"
                      :class="isDrink == alcohol.alcohol_id ? 'choose' : ''"
                      @click="drink(alcohol.alcohol_id)"
                    />
                  </b-row>
                  <b-row>
                    <b-col>
                      <p>年収</p>
                      <SearchProfile
                        v-model="income"
                        :options="commonTutorials.annualIncomes"
                        :obligatory="true"
                        @select-option="income = $event"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <button
                        class="btn-save-info f-w3"
                        type="submit"
                        @click="checkError()"
                      >
                        確定する
                      </button>
                    </b-col>
                  </b-row>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
        <card-footer ref="footer" />
      </div>
    </template>
  </div>
</template>

<script>
import CardFooter from "@/components/CardFooter.vue";
import CardHeader from "@/components/CardHeader.vue";
// import TutorialAddImage from "@/components/Men/Tutorial/TutorialAddImage.vue";
import TutorialNotificationImage from "@/components/Men/Tutorial/TutorialNotificationImage.vue";
// import TutorialPhone from "@/components/Men/Tutorial/TutorialPhone.vue";
// import TutorialNickname from "@/components/Men/Tutorial/TutorialNickname.vue";
// import TutorialEdit from "@/components/Men/Tutorial/TutorialEdit.vue";
import TutorialBasicInfo from "@/components/Men/Tutorial/TutorialBasicInfo.vue";
// import TutorialPoint from "@/components/Men/Tutorial/TutorialPoint.vue";
// import TutorialLaw from "@/components/Men/Tutorial/TutorialWelcome.vue";
import TutorialEditProfileComplete from "@/components/Men/Tutorial/TutorialEditProfileComplete.vue";
import TutorialVehicle from "@/components/Men/Tutorial/TutorialVehicle.vue";
import TutorialGolfMember from "@/components/Men/Tutorial/TutorialGolfMember";
import TutorialDating from "@/components/Men/Tutorial/TutorialDating.vue";
import TutorialComplete from "../../components/Men/Tutorial/TutorialComplete.vue";
import CareerTutorial from "@/components/Men/Tutorial/CareerTutorial.vue";
import HistoryTutorial from "@/components/Men/Tutorial/HistoryTutorial.vue";
import AreaTutorial from "@/components/Men/Tutorial/AreaTutorial.vue";
import ParticipateTutorial from "@/components/Men/Tutorial/ParticipateTutorial.vue";
import TutorialNumberOfRound from "@/components/Men/Tutorial/TutorialNumberOfRound.vue";
import TutorialTobacco from "@/components/Men/Tutorial/TutorialTobacco";
import TutorialSake from "@/components/Men/Tutorial/TutorialSake";
import TutorialAnnualIncome from "@/components/Men/Tutorial/TutorialAnnualIncome";
import SearchProfile from "./SelectProfile.vue";
import { mapGetters } from "vuex";
import SelectMultiFilterCast from "@/views/Cast/SelectMultiFilterCast";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { MALE_PREGO_RANK, FEMALE_PREGO_RANK } from "@/utils/const";
import ImageProfile from "@/views/imageProfile";
import ImageProfileExtra from "@/views/imageProfileExtra";
import InviteFriendLine from "@/views/Common/InviteFriendLine.vue";
import {
  DEFAULT_VIDEO_VALUE,
  DEFAULT_VIDEO_TIME,
  MAX_IMAGE_SIZE,
  ALLOW_FILE_TYPE_IMAGE
} from "@/definition/constants";
// import firebase from "@/firebase";

// const storage = firebase.storage;

export default {
  name: "EditProfile",
  components: {
    "card-footer": CardFooter,
    "card-header": CardHeader,
    // "tutorial-add-image": TutorialAddImage,
    "tutorial-notification-image": TutorialNotificationImage,
    // "tutorial-phone": TutorialPhone,
    // "tutorial-nickname": TutorialNickname,
    // "tutorial-edit": TutorialEdit,
    "tutorial-basic-info": TutorialBasicInfo,
    // "tutorial-point": TutorialPoint,
    // "tutorial-law": TutorialLaw,
    "tutorial-edit-complete": TutorialEditProfileComplete,
    "tutorialVehicle-body": TutorialVehicle,
    "tutorialGolfMember-body": TutorialGolfMember,
    "tutorialDating-body": TutorialDating,
    "tutorialComplete-body": TutorialComplete,
    "career-tutorial": CareerTutorial,
    "history-tutorial": HistoryTutorial,
    "area-tutorial": AreaTutorial,
    "participate-tutorial": ParticipateTutorial,
    "tutorial-number-of-round": TutorialNumberOfRound,
    "tutorial-tobacco": TutorialTobacco,
    "tutorial-sake": TutorialSake,
    "tutorial-annual-in-come": TutorialAnnualIncome,
    SearchProfile,
    SelectMultiFilterCast,
    Cropper,
    ImageProfile,
    ImageProfileExtra,
    InviteFriendLine
  },
  metaInfo() {
    return {
      title: "プロフィール編集",
      titleTemplate: "%s | PreGo"
    };
  },
  data() {
    return {
      showTutorial: true,
      uploadVideo: 0,
      invite: null,
      FEMALE_PREGO_RANK: FEMALE_PREGO_RANK,
      MALE_PREGO_RANK: MALE_PREGO_RANK,
      poster: null,
      aspectRatio1: 16 / 16,
      minAspectRatio: 335,
      maxAspectRatio: 335,
      propsUser: {
        image_url: null,
        rank: null
      },
      result: null,
      imageTest: null,
      test: null,
      isShowCropper: false,
      model: false,
      modelSrc: "",
      crap: false,
      lists: [
        {
          img: null
        }
      ],
      option: {
        img: null,
        size: 1,
        full: false,
        outputType: "png",
        canMove: true,
        fixedBox: true,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        autoCropHeight: 170,
        centerBox: false,
        high: true,
        max: 9999
      },
      autoCropWidth: 237,
      show: true,
      fixed: true,
      fixedNumber: [12, 16],
      golf_experience: null,
      male_golf_rank: null,
      male_prego_rank: null,
      residencesState: null,
      golf_frequency: null,
      residenceAreaName: null,
      user_height: null,
      heightOptions: [],
      user_biography: "",
      top: null,
      name: "",
      surname: "",
      nameKana: "",
      surnameKana: "",
      nickname: "",
      birthday: "",
      phone: "",
      phoneNumber: null,
      height: "",
      golf_course: null,
      selfIntroduction: "",
      image: null,
      step: null,
      video: null,
      isShowIconPlayVideo: false,
      headerTitle: {
        text: "プロフィール編集",
        isShowTitle: true,
        isShowLogo: false,
        step: false
      },
      listImageId: [
        { id: null },
        { id: null },
        { id: null },
        { id: null },
        { id: null },
        { id: null },
        { id: null }
      ],
      areaValue: [],
      selectedArea: [],
      listArea: [
        { name: "プレーエリアA", value: "A" },
        { name: "プレーエリアB", value: "B" },
        { name: "プレーエリアC", value: "C" },
        { name: "プレーエリアD", value: "D" },
        { name: "プレーエリアE", value: "E" },
        { name: "プレーエリアF", value: "F" },
        { name: "プレーエリアG", value: "G" },
        { name: "プレーエリアH", value: "H" },
        { name: "プレーエリアI", value: "I" },
        { name: "プレーエリアK", value: "K" },
        { name: "プレーエリアL", value: "L" }
      ],
      user: null,
      datingStatus: null,
      job: null,
      address: null,
      bestScore: null,
      prefecture: null,
      bestScoreOptions: [],
      avgScore: null,
      occupation: null,
      avgScoreOptions: [],
      golfHistory: null,
      around: null,
      golfAddress: null,
      golfMember: null,
      golf_member: null,
      golfMemberOptions: [{ name: "あり" }, { name: "なし" }],
      golfCourse: null,
      income: null,
      method: null,
      methodOptions: [{ name: "PICKUP" }, { name: "現地集合" }],
      isShowCar: false,
      car: null,
      car_flag: null,
      car_model: null,
      vehicleType: "",
      carOptions: [{ name: "あり" }, { name: "なし" }, { name: "レンタカー" }],
      isSmoke: null,
      isSmokeCigarette: true,
      isDrink: null,
      isOr: false,
      isNotDrink: true,
      isNoSmoke: false,
      listPictures: [
        { file: null },
        { file: null },
        { file: null },
        { file: null },
        { file: null }
      ],
      relationship: null,
      listDeleteImage: [],
      listImage: [
        { image_url: null },
        { image_url: null },
        { image_url: null },
        { image_url: null },
        { image_url: null },
        { image_url: null },
        { image_url: null }
      ],
      userDetail: null,
      avatarLeft: null,
      avatarRight: null,
      imageProfile: null,
      best_score: null,
      avg_score: null,
      videoMP4: null,
      idVideo: null,
      listImageModel: [
        { image: null },
        { image: null },
        { image: null },
        { image: null },
        { image: null },
        { image: null },
        { image: null }
      ],
      isChangeVideo: false,
      postCode: null,
      postCodeOld: null,
      areaDetail: null,
      listAreaDetail: [],
      residence: null,
      areaDetailOld: null,
      area: null,
      isNew: false
    };
  },
  computed: {
    ...mapGetters({
      commonTutorials: "common/commonTutorials",
      isLoading: "common/isLoading"
    })
  },
  async created() {
    this.$store.dispatch("common/setIsLoading", true);
    await this.$store.dispatch("common/commonsTutorials", {
      array_common_request: [
        "prefectures",
        "residences",
        "occupations",
        "golfExperiences",
        "golfAreas",
        "golfFrequencies",
        "cigarettes",
        "alcohols",
        "annualIncomes",
        "relationships",
        "appearances",
        "figures",
        "otherPreferences",
        "areas"
      ]
    });
    this.user = this.$store.getters["auth/user"];
    // await this.checkStepTutorial();
    // this.step = localStorage.getItem("step");
    // if (this.step === 1) {
    //   this.getStep(1);
    // }
    if (this.user.registration_status > 1) {
      await this.$store.dispatch("userApp/getInfoUser", this.user.user_id);
      this.userDetail = this.$store.getters["userApp/user"].user;
      if (!this.userDetail.nickname) {
        this.isNew = true;
      }
      if (this.userDetail?.post_code) {
        const postCode = String(this.userDetail.post_code);
        this.postCode = postCode.substr(0, 3) + "-" + postCode.substr(3, 4);
      }
      if ((this.commonTutorials, this.user, this.userDetail)) {
        await this.setRelationShip();
      }
      await this.setScore();
      await this.detailUser();
    } else {
      this.$router.push({ name: "RegisterProfile" });
    }
    this.$store.dispatch("common/setIsLoading", false);
  },
  methods: {
    focusTextArea() {
      document.getElementById("user_biography").focus();
    },
    chooseVideo(event) {
      this.isChangeVideo = true;
      const vm = this;
      var file = event.target.files[0];
      vm.videoMP4 = file;
      var fileReader = new FileReader();
      if (file.type.match("image")) {
        fileReader.onload = function() {
          var img = document.createElement("img");
          img.src = fileReader.result;
          document.getElementById("div").appendChild(img);
        };
        fileReader.readAsDataURL(file);
      } else {
        fileReader.onload = function() {
          var blob = new Blob([fileReader.result], { type: file.type });
          var url = URL.createObjectURL(blob);
          var video = document.createElement("video");
          var timeupdate = function() {
            if (snapImage()) {
              video.removeEventListener("timeupdate", timeupdate);
              video.pause();
            }
          };
          video.addEventListener("loadeddata", function() {
            if (snapImage()) {
              video.removeEventListener("timeupdate", timeupdate);
            }
          });
          var snapImage = function() {
            var canvas = document.createElement("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas
              .getContext("2d")
              .drawImage(video, 0, 0, canvas.width, canvas.height);
            var image = canvas.toDataURL("image/jpeg");
            var success = image;
            if (success) {
              if (video.duration > DEFAULT_VIDEO_TIME) {
                vm.$toast(
                  "動画は15秒以内のものを指定してください",
                  "通知",
                  "danger"
                );
                vm.videoMP4 = null;
                vm.isShowIconPlayVideo = false;
                vm.video = null;
                return success;
              } else if (video && video.size > DEFAULT_VIDEO_VALUE) {
                this.$toast(
                  "ビデオは100MBを超えることはできません",
                  "通知",
                  "danger"
                );
                vm.videoMP4 = null;
                vm.isShowIconPlayVideo = false;
                vm.video = null;
                return success;
              } else {
                vm.poster = image;
                vm.isShowIconPlayVideo = true;
                URL.revokeObjectURL(url);
              }
            }
            return success;
          };
          video.addEventListener("timeupdate", timeupdate);
          video.preload = "metadata";
          video.src = url;
          video.muted = true;
          video.playsInline = true;
          video.play();
          vm.video = url;
          document.getElementById("video").src = url;
        };
        fileReader.readAsArrayBuffer(file);
      }
      this.uploadVideo++;
    },
    resetAddFriendPrego() {
      localStorage.removeItem("add-friend-line");
      this.invite = null;
      // localStorage.setItem("step", 4);
      // this.step = 4;
      this.returnHome();
    },
    addFriendPrego() {
      window.open("https://line.me/R/ti/p/" + process.env.VUE_APP_LINE_ID);
      localStorage.removeItem("add-friend-line");
      this.invite = null;
      // localStorage.setItem("step", 4);
      // this.step = 4;
      this.returnHome();
    },
    returnHome() {
      localStorage.removeItem("step");
      document.scrollingElement.style.position = "static";
      document.scrollingElement.style.overflow = "auto";
      document.scrollingElement.style["overscroll-behavior"] = "auto";
      this.$router.push({
        name: "Home",
        params: {
          showPopup: "show"
        }
      });
    },
    getPause() {
      let video = document.getElementById("video");
      if (video && video.ended) {
        this.isShowIconPlayVideo = true;
        return true;
      }
      return false;
    },
    aspectRatio() {
      return {
        minimum: this.aspectRatio1 || this.minAspectRatio,
        maximum: this.aspectRatio1 || this.maxAspectRatio
      };
    },
    onChange({ coordinates, canvas }) {
      this.coordinates = coordinates;
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image
      this.result = canvas.toDataURL("image/jpeg");
      // fetch(this.result)
      //   .then((res) => res.blob())
      //   .then(console.log);
    },
    stencilSize() {
      return {
        width: this.minAspectRatio,
        height: this.maxAspectRatio
      };
    },
    imgLoad() {},
    cropMoving() {},
    changeImg() {
      this.option.img = this.lists[~~(Math.random() * this.lists.length)].img;
    },
    startCrop() {
      // start
      this.crap = true;
      this.$refs.cropper.startCrop();
    },
    stopCrop() {
      //  stop
      this.crap = false;
      this.$refs.cropper.stopCrop();
    },
    clearCrop() {
      // clear
      this.$refs.cropper.clearCrop();
    },
    refreshCrop() {
      // clear
      this.$refs.cropper.refresh();
    },
    // changeScale(num) {
    //   num = num || 1
    //   this.$refs.cropper.changeScale(num)
    // },
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    cancel() {
      this.listImageModel[this.test].image = null;
      this.isShowCropper = false;
    },
    async down() {
      this.$root.$refs.loading.start();
      const base64Response = await fetch(this.result);
      const blod = await base64Response.blob();
      var img = await window.URL.createObjectURL(blod);
      this.propsUser.image_url = img;
      this.propsUser.rank = this.userDetail.male_prego_rank;
      if (this.test == 0) {
        this.$refs["modal-image-profile"].openModal();
        this.$root.$refs.loading.finish();
        return;
      }
      this.$refs["modal-image-profile-extra"].openModal();
      this.$root.$refs.loading.finish();
    },
    closeModal() {
      if (this.test == 0) {
        this.$refs["modal-image-profile"].closeModal();
        return;
      }
      this.$refs["modal-image-profile-extra"].closeModal();
    },
    async acceptModal() {
      this.$root.$refs.loading.start();
      const base64Response = await fetch(this.result);
      const blod = await base64Response.blob();
      var img = await window.URL.createObjectURL(blod);
      if (this.test == 0) {
        this.$refs["modal-image-profile"].closeModal();
        this.avatarLeft = blod;
        this.listImageModel[0].image = null;
        this.listImage[0].image_url = img;
      } else if (this.test == 1) {
        this.avatarRight = blod;
        this.listImageModel[1].image = null;
        this.listImage[1].image_url = img;
      } else {
        this.$refs["modal-image-profile-extra"].closeModal();
        this.listPictures[this.test - 2].file = blod;
        this.listImageModel[this.test].image = null;
        this.listImage[this.test].image_url = img;
      }
      this.isShowCropper = false;
      this.$root.$refs.loading.finish();
    },
    backTutorial() {
      if (this.user.login_type == "LINE") {
        if (this.step == 1) {
          this.step = 2;
          localStorage.setItem("step", this.step);
          return;
        }
      } else {
        if (this.step >= 2) {
          if (this.step == 11) {
            this.step = 4;
          } else {
            this.step = localStorage.getItem("step") - 1;
          }
          localStorage.setItem("step", this.step);
          return;
        }
      }

      this.$router.push({ name: "Home" });
    },
    handleBlur() {
      this.nickname = this.nickname != null ? this.nickname.trim() : "";
      this.user_biography =
        this.user_biography != null ? this.user_biography.trim() : "";
      this.golf_course =
        this.golf_course != null ? this.golf_course.trim() : null;
      this.car_model = this.car_model != null ? this.car_model.trim() : null;
    },
    async detailUser() {
      this.male_golf_rank = this.userDetail.male_golf_rank;
      this.male_prego_rank = this.userDetail.male_prego_rank;
      this.nickname = this.user.nickname;
      this.prefecture = this.userDetail.prefecture;
      this.residence = this.userDetail.residence;
      // this.areaDetail = this.userDetail.area;
      const postal_code = require("japan-postal-code");
      const self = this;
      postal_code.get(self.userDetail.post_code, async function(address) {
        self.area = address.area;
        const params = {
          prefecture_name: address.prefecture,
          city_name: address.city,
          area_name: address.area
        };

        await self.$store
          .dispatch("common/getListArea", params)
          .then(res => {
            self.listAreaDetail = res.data;
            self.areaDetail = self.listAreaDetail.findIndex(
              item => item.name === self.userDetail.area_name
            );
            if (self.userDetail.area_name) {
              self.areaDetailOld = self.listAreaDetail[self.areaDetail].id;
            }
          })
          .catch(error => {
            console.log(error);
          });
      });
      // this.user_height = this.user.height;
      this.user_biography = this.user.biography;
    },
    async setRelationShip() {
      this.getOccupations();
      this.getGolfExperience();
      this.getMetting();
      this.getGolfMember();
      this.getGolfFrequency();
      this.getIncome();
      this.getGolfArea();
      this.carFlag();
      this.getImage();
      for (let i = 0; i < this.commonTutorials.relationships.length; i++) {
        if (
          this.user.relationship_id ===
          this.commonTutorials.relationships[i].relationship_id
        ) {
          this.relationship = i;
          return;
        }
      }
    },
    async getGolfArea() {
      for (let i = 0; i < this.commonTutorials.golfAreas.length; i++) {
        for (let j = 0; j < this.userDetail.golf_areas.length; j++) {
          if (
            this.userDetail.golf_areas[j].golf_area_id ===
            this.commonTutorials.golfAreas[i].golf_area_id
          ) {
            this.areaValue.push(i);
          }
        }
      }
    },
    async getGolfExperience() {
      for (let i = 0; i < this.commonTutorials.golfExperiences.length; i++) {
        if (
          this.user.golf_experience_id ===
          this.commonTutorials.golfExperiences[i].golf_experience_id
        ) {
          this.golf_experience = i;
          return;
        }
      }
    },
    async getOccupations() {
      this.isSmoke = this.user.cigarette_id;
      this.isDrink = this.user.alcohol_id;
      for (let i = 0; i < this.commonTutorials.occupations.length; i++) {
        if (
          this.user.occupation_id ===
          this.commonTutorials.occupations[i].occupation_id
        ) {
          this.occupation = i;
          return;
        }
      }
    },
    async getImage() {
      for (let i = 0; i < this.userDetail.image_urls.length; i++) {
        //get video
        if (this.userDetail.image_urls[i].type == 3) {
          this.video = this.userDetail.image_urls[i].image_url;
          this.idVideo = this.userDetail.image_urls[i].id;
          if (this.video !== null) {
            this.isShowIconPlayVideo = true;
          }
        }
        if (this.userDetail.image_urls[i].type == 1) {
          if (this.userDetail.image_urls[i].display_order == 2)
            this.poster = this.userDetail.image_urls[i].image_url;
          this.listImage[
            this.userDetail.image_urls[i].display_order - 1
          ].image_url = this.userDetail.image_urls[i].image_url;
          this.listImageId[
            this.userDetail.image_urls[i].display_order - 1
          ].id = this.userDetail.image_urls[i].id;
        }
      }
    },
    async getGolfFrequency() {
      for (let i = 0; i < this.commonTutorials.golfFrequencies.length; i++) {
        if (
          this.user.golf_frequency_id ===
          this.commonTutorials.golfFrequencies[i].golf_frequency_id
        ) {
          this.golf_frequency = i;
          return;
        }
      }
    },
    async getMetting() {
      if (this.user.meeting_method == "1") {
        this.method = 0;
      } else if (this.user.meeting_method == "2") {
        this.method = 1;
      }
    },
    async getGolfMember() {
      if (this.userDetail.golf_membership == 0) {
        this.golf_member = 1;
      } else if (this.userDetail.golf_membership == 1) {
        this.golf_member = 0;
        this.golf_course = this.userDetail.membership_course;
      }
    },
    async getIncome() {
      for (let i = 0; i < this.commonTutorials.annualIncomes.length; i++) {
        if (
          this.user.annual_income_id ===
          this.commonTutorials.annualIncomes[i].annual_income_id
        ) {
          this.income = i;
          return;
        }
      }
    },
    async carFlag() {
      if (this.userDetail.car_flag == 1) {
        this.car = 0;
      } else if (this.userDetail.car_flag == 2) {
        this.car = 2;
      } else if (this.userDetail.car_flag == 0) {
        this.car = 1;
      }
      if (this.userDetail.car_model) {
        if (this.userDetail.car_model != "null") {
          this.car_model = this.userDetail.car_model;
        }
      }
    },
    setScore() {
      for (let bestScore = 60; bestScore <= 160; bestScore++) {
        this.bestScoreOptions.push({ name: bestScore });
      }
      this.bestScoreOptions.push({ name: "160以上" });

      for (let avgScore = 60; avgScore <= 180; avgScore += 5) {
        this.avgScoreOptions.push({ name: avgScore });
      }
      this.avgScoreOptions.push({ name: "180以上" });

      for (let height = 140; height <= 220; height++) {
        this.heightOptions.push({ name: height });
      }
      if (this.user.height >= 140 && this.user.height <= 220) {
        this.user_height = this.user.height - 140;
      } else {
        this.user_height = null;
      }
      if (
        this.user.male_golf_rank_id == 1 ||
        this.user.male_golf_rank_id == null
      ) {
        if (this.user.best_score >= 60 && this.user.best_score <= 160) {
          this.best_score = this.user.best_score - 60;
        } else if (this.user.best_score == 999) {
          this.best_score = 101;
        } else {
          this.bestScore = null;
        }
        if (this.user.avg_score >= 60 && this.user.avg_score <= 180) {
          this.avg_score = this.user.avg_score / 5 - 12;
        } else if (this.user.avg_score == 999) {
          this.avg_score = 25;
        } else {
          this.avg_score = null;
        }
      }
    },
    deleteImage(index) {
      this.listImageModel[index].file = null;
      this.listImage[index].image_url = null;
    },
    deleteVideo() {
      const video_play = document.getElementById("video");
      video_play.poster = null;
      video_play.pause();
      this.video = null;
      this.videoMP4 = null;
      this.isShowIconPlayVideo = false;
      this.avatarRight = null;
      this.poster = null;
      this.listImageModel[1].file = null;
      this.listImage[1].image_url = null;
      this.uploadVideo++;
    },
    async chooseImage(event, index, isImage = false) {
      const uploadFile = event.target.files[0];
      if (isImage && !ALLOW_FILE_TYPE_IMAGE.includes(uploadFile.type)) {
        this.$toast(
          "無効な画像形式. png、jpg、jpeg形式の画像を選択してください",
          "エラー",
          "danger"
        );
        return;
      }
      if (isImage && uploadFile.size > MAX_IMAGE_SIZE) {
        this.$toast(
          "プロフィール写真は3MB以内にしてください",
          "エラー",
          "danger"
        );
        return;
      }
      this.$root.$refs.loading.start();
      let form = new FormData();
      this.option.img = null;
      form.append("video", event.target.files[0]);
      for (var value of form.values()) {
        if (value.size) {
          switch (index) {
            case 0:
              this.listImageModel[0].image = value;
              this.test = 0;
              this.aspectRatio1 = 16 / 16;
              this.minAspectRatio = 335;
              this.maxAspectRatio = 335;
              break;
            case 1:
              this.listImageModel[1].image = value;
              this.test = 1;
              this.aspectRatio1 = 16 / 16;
              this.minAspectRatio = 335;
              this.maxAspectRatio = 335;
              break;
            default:
              this.listImageModel[index].image = value;
              this.test = index;
              this.aspectRatio1 = 16 / 16;
              this.minAspectRatio = 335;
              this.maxAspectRatio = 335;
              break;
          }
          var reader = new FileReader();
          var vm = this;
          reader.onload = e => {
            let data;
            if (typeof e.target.result === "object") {
              data = window.URL.createObjectURL(new Blob([e.target.result]));
            } else {
              data = e.target.result;
            }
            vm.imageTest = data;
            vm.isShowCropper = true;
          };
          reader.readAsDataURL(value);
          this.$root.$refs.loading.finish();
        }
      }
    },
    play() {
      const video = document.getElementById("video");
      if (video.paused || video.ended) {
        video.play();
        this.isShowIconPlayVideo = false;
        // video.webkitRequestFullscreen();
      } else {
        video.pause();
        this.isShowIconPlayVideo = true;
      }
    },
    async checkStepTutorial() {
      this.step = localStorage.getItem("step");
      if (this.step) {
        document.getElementsByClassName(
          "app-container__content"
        )[0].style.overflow = "hidden";
        this.headerTitle.step = true;
      }
    },
    getStep(value) {
      localStorage.setItem("step", value);
      this.step = localStorage.getItem("step");
    },
    setLocationInput(ref, value) {
      this.top += this.$refs[ref].getBoundingClientRect().top - value;
      document.scrollingElement.style.top = `-${this.top}px`;
    },
    reflectData(data) {
      this[data.name] = data.value;
    },
    addForm(value, display_order, image_url) {
      if (value) {
        let item = {
          url: value,
          display_order: display_order,
          id: this.listImageId[display_order - 1].id
        };
        this.file.push(item);
      } else if (image_url == null && this.listImageId[display_order - 1].id) {
        this.listDeleteImage.push(this.listImageId[display_order - 1].id);
      }
    },
    form() {
      (this.file = []),
        (this.listDeleteImage = []),
        this.addForm(this.avatarLeft, 1, this.listImage[0].image_url);
      this.addForm(this.avatarRight, 2, this.listImage[1].image_url);
      this.addForm(this.listPictures[0].file, 3, this.listImage[2].image_url);
      this.addForm(this.listPictures[1].file, 4, this.listImage[3].image_url);
      this.addForm(this.listPictures[2].file, 5, this.listImage[4].image_url);
      this.addForm(this.listPictures[3].file, 6, this.listImage[5].image_url);
      this.addForm(this.listPictures[4].file, 7, this.listImage[6].image_url);
    },
    async checkError() {
      const observer = this.$refs.observer;
      const success = await observer.validate();

      if (!success) {
        for (const key of Object.keys(observer.fields)) {
          if (observer.fields[key].invalid) {
            let pos = observer.refs[key].$el.style.position;
            let top = observer.refs[key].$el.style.top;
            observer.refs[key].$el.style.position = "relative";
            observer.refs[key].$el.style.top = "-300px";
            observer.refs[key].$el.scrollIntoView({
              behavior: "smooth",
              block: "start"
            });
            observer.refs[key].$el.style.top = top;
            observer.refs[key].$el.style.position = pos;
            return;
          }
        }
      }
    },
    changeNickName(e) {
      this.nickname = e.target.value;
    },
    changeBiography(e) {
      this.user_biography = e.target.value;
    },
    async onSubmit() {
      let vd = document.getElementById("video");
      if (vd.duration > DEFAULT_VIDEO_TIME && this.videoMP4) {
        this.$toast("動画は15秒以内のものを指定してください", "通知", "danger");
      } else if (this.videoMP4 && this.videoMP4.size > DEFAULT_VIDEO_VALUE) {
        this.$toast("動画は15秒以内のものを指定してください", "通知", "danger");
      } else if (this.listImage[0].image_url == null) {
        this.$toast(
          "プロフィール画像は必ず指定してください。",
          "通知",
          "danger"
        );
      } else {
        if (
          document.getElementById("video").poster &&
          this.poster &&
          this.isChangeVideo
        ) {
          const base64Response = await fetch(this.poster);
          const blod = await base64Response.blob();
          var img = await window.URL.createObjectURL(blod);
          this.avatarRight = blod;
          this.listImage[1].image_url = img;
        }
        this.form();
        let form = new FormData();
        let golf_area_ids = [];
        for (let i = 0; i < this.areaValue.length; i++) {
          golf_area_ids.push(
            this.commonTutorials.golfAreas[this.areaValue[i]].golf_area_id
          );
        }
        if (this.videoMP4) {
          form.append("video", this.videoMP4);
        } else if (this.video == null && this.idVideo) {
          this.listDeleteImage.push(this.idVideo);
        }
        if (this.listDeleteImage.length > 0) {
          form.append("delete_image_ids", this.listDeleteImage);
        }
        form.append("golf_area_ids", golf_area_ids.toString());
        form.append("nickname", this.nickname);
        if (this.file.length >= 1) {
          // var avatar = this.file.find(f => f.display_order === 1);
          for (let i = 0; i < this.file.length; i++) {
            form.append("image_url[" + i + "][url]", this.file[i].url);
            form.append(
              "image_url[" + i + "][display_order]",
              this.file[i].display_order
            );
            form.append("image_url[" + i + "][id]", this.file[i].id);
            form.append("image_url[" + i + "][extension]", "jpg");
          }
        }
        // if (this.relationship != null) {
        form.append(
          "relationship_id",
          this.relationship
            ? this.commonTutorials.relationships[this.relationship]
                .relationship_id
            : 0
        );
        // }
        // if (this.occupation != null) {
        form.append(
          "occupation_id",
          this.occupation
            ? this.commonTutorials.occupations[this.occupation].occupation_id
            : 0
        );
        // }

        // if (this.user_height != null) {
        form.append(
          "height",
          this.user_height ? this.heightOptions[this.user_height].name : 0
        );
        // }
        form.append("biography", this.user_biography);
        if (
          this.user.male_golf_rank_id == 1 ||
          this.user.male_golf_rank_id == null
        ) {
          if (this.best_score == 101) {
            form.append("best_score", 999);
          } else {
            form.append(
              "best_score",
              this.bestScoreOptions[this.best_score].name
            );
          }

          if (this.avg_score == 25) {
            form.append("avg_score", 999);
          } else {
            form.append("avg_score", this.avgScoreOptions[this.avg_score].name);
          }
        }

        // if (this.income != null) {
        form.append(
          "annual_income_id",
          this.income
            ? this.commonTutorials.annualIncomes[this.income].annual_income_id
            : 0
        );
        // }
        // if (this.golf_experience != null) {
        form.append(
          "golf_experience_id",
          this.golf_experience
            ? this.commonTutorials.golfExperiences[this.golf_experience]
                .golf_experience_id
            : 0
        );
        // }
        // if (this.golf_frequency != null) {
        form.append(
          "golf_frequency_id",
          this.golf_frequency
            ? this.commonTutorials.golfFrequencies[this.golf_frequency]
                .golf_frequency_id
            : 0
        );
        // }
        form.append("meeting_method", this.method == 0 ? 1 : 2);
        if (this.car != null) {
          if (this.car == 0) {
            form.append("car_flag", 1);
          } else if (this.car == 1) {
            form.append("car_flag", 0);
          } else {
            form.append("car_flag", 2);
          }
        } else {
          form.append("car_flag", 3);
        }
        form.append("car_model", this.car == 0 ? this.car_model : null);
        if (this.golf_member != null) {
          form.append("has_golf_membership", this.golf_member == 0 ? 1 : 0);
        } else {
          form.append("has_golf_membership", 2);
        }
        form.append(
          "membership_course",
          this.golf_member == 0 ? this.golf_course : null
        );
        form.append("alcohol_id", this.isDrink != null ? this.isDrink : 0);
        form.append("cigarette_id", this.isSmoke != null ? this.isSmoke : 0);
        form.append(
          "prefecture_master_id",
          this.listAreaDetail[this.areaDetail].id
        );
        form.append("street_name", this.userDetail.street_name);
        if (this.userDetail.house_name) {
          form.append("house_name", this.userDetail.house_name);
        }
        if (this.postCode?.replace("-", "") != this.userDetail.post_code) {
          if (this.listAreaDetail[this.areaDetail].id != this.areaDetailOld) {
            form.append("post_code", this.postCode?.replace("-", ""));
          }
        }
        form.append("_method", "PUT");
        this.$root.$refs.loading.start();
        this.$store.dispatch("editProfile/editProfile", form).then(() => {
          if (this.isNew) {
            this.$router.push({ name: "Home" });
          } else {
            this.$router.push({ name: "ProfileViewMyProfile" });
          }
          this.$toast("プロフィール情報を更新しました。", "通知", "success");
        });
        this.$root.$refs.loading.finish();
      }
    },
    smoke(index) {
      if (this.isSmoke == index) {
        this.isSmoke = 0;
        return;
      }
      this.isSmoke = index;
    },
    drink(index) {
      if (this.isDrink == index) {
        this.isDrink = 0;
        return;
      }
      this.isDrink = index;
    },
    addImage() {
      this.getStep(9);
    },
    uploadImage(event) {
      for (var value of event.values()) {
        this.createImage(value);
      }
    },
    async createImage(file) {
      let formData = new FormData();
      formData.append("step", 3);
      formData.append("image_url", file);
      formData.append("display_order", 1);
      formData.append("_method", "PUT");
      formData.append("extension", "jpg");
      await this.$store
        .dispatch("editProfile/updateProfileByFormData", formData)
        .then(async () => {
          // let storageRef = storage.ref();
          // const imgRef = storageRef.child(`users/${this.user.user_id}/avatar`);
          // imgRef
          //   .put(file)
          //   .then(() => {
          //     imgRef.getDownloadURL().then(url => {
          //       let dataUser = {
          //         userId: this.user.user_id.toString(),
          //         field: {
          //           imageUrl: url
          //         }
          //       };
          //       this.$store.dispatch("firebase/updateUser", dataUser);
          //     });
          //   })
          //   .catch(error => {
          //     throw error;
          //   });
          // if (this.user.login_type == "LINE") {
          //   this.getStep(3);
          //   await this.$store.dispatch("editProfile/updateProfile", {
          //     step: 10
          //   });
          // } else {
          //   this.getStep(2);
          // }
          this.getStep(3);
          await this.$store.dispatch("editProfile/updateProfile", {
            step: 10
          });
          this.$root.$refs.loading.finish();
        })
        .catch(error => {
          this.$root.$refs.loading.finish();
          throw error;
        });
      var reader = new FileReader();
      var vm = this;
      reader.onload = e => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    tutorialPhone(event) {
      this.phone = event;
      this.$store
        .dispatch("editProfile/updateProfile", {
          step: 4,
          phone: this.phone
        })
        .then(() => {
          this.getStep(7);
        });
    },
    tutorialNickname(value) {
      var params = {
        step: 5,
        nickname: value.nickname
      };
      // if (value.name !== "") {
      //   params.last_name_kanji = value.name;
      // }
      // if (value.surName !== "") {
      //   params.first_name_kanji = value.surName;
      // }
      // if (value.nameKana !== "") {
      //   params.last_name_kana = value.nameKana;
      // }
      // if (value.surnameKana !== "") {
      //   params.first_name_kana = value.surnameKana;
      // }
      this.$store.dispatch("editProfile/updateProfile", params).then(() => {
        let dataUser = {
          userId: this.user.user_id.toString(),
          field: {
            nickname: value.nickname
          }
        };
        this.$store.dispatch("firebase/updateUser", dataUser);
        this.getStep(4);
      });
    },
    getNickname(event) {
      this.nickname = event;
    },
    tutorialEdit(value) {
      this.$store
        .dispatch("editProfile/updateProfile", {
          step: 6,
          date_of_birth: value
        })
        .then(() => {
          this.getStep(6);
        });
    },
    date(date) {
      this.birthday = date;
    },
    getDate(date) {
      this.birthday = date;
    },
    tutorialBasicInfo(params) {
      let payload = {
        step: 7,
        prefecture_name: params["prefecture_name"],
        city_name: params["city_name"],
        area_name: params["area_name"],
        street_name: params["street_name"],
        house_name: params["house_name"],
        post_code: params["post_code"],
        date_of_birth: params["date"],
        nickname: params["nickname"],
        phone: params["phone"],
        best_score:
          params["bestScore"] != "160以上" ? params["bestScore"] : 999,
        avg_score: params["avgScore"] != "180以上" ? params["avgScore"] : 999
      };
      this.$store.dispatch("editProfile/updateProfile", payload).then(() => {
        this.showTutorial = false;
        let dataUser = {
          userId: this.user.user_id.toString(),
          field: {
            nickname: params["nickname"]
          }
        };
        this.$store.dispatch("firebase/updateUser", dataUser);
        // if (this.user.login_type == "LINE") {
        //   this.getStep(1);
        // } else {
        //   this.getStep(3);
        //   this.$store.dispatch("editProfile/updateProfile", {
        //     step: 10
        //   });
        // }
        this.getStep(2);
        // window.location.reload();
      });
    },
    getAddress(value) {
      this.address = value;
    },
    tutorialPoint(event) {
      this.$store
        .dispatch("editProfile/updateProfile", {
          step: 8,
          best_score: event.bestScore != "160以上" ? event.bestScore : 999,
          avg_score: event.avgScore != "180以上" ? event.avgScore : 999
        })
        .then(() => {
          this.getStep(8);
        });
    },
    getBestScore(event) {
      this.bestScore = event;
    },
    getAvgScore(event) {
      this.avgScore = event;
    },
    tutorialLaw() {
      this.getStep(3);
      // this.$store.dispatch("editProfile/updateProfile", {
      //   step: 3
      // });
    },
    tutorialEditComplete() {
      this.getStep(11);
    },
    tutorialCareer(event) {
      this.$store
        .dispatch("editProfile/updateProfile", {
          step: 11,
          height: event.height,
          occupation_id: event.job
        })
        .then(() => {
          this.getStep(12);
        });
    },
    tutorialHistory(event) {
      this.$store
        .dispatch("editProfile/updateProfile", {
          step: 12,
          golf_experience_id: event.golfHistory
        })
        .then(() => {
          this.getStep(13);
        });
    },
    tutorialArea(event) {
      this.$store
        .dispatch("editProfile/updateProfile", {
          step: 13,
          golf_area_ids: event.golfAddress
        })
        .then(() => {
          this.getStep(14);
        });
    },
    tutorialParticipate(event) {
      this.$store
        .dispatch("editProfile/updateProfile", {
          step: 14,
          meeting_method: event.method
        })
        .then(() => {
          this.getStep(15);
        });
    },
    tutorialVehicle() {
      if (this.car_flag) {
        this.$store
          .dispatch("editProfile/updateProfile", {
            step: 19,
            car_model: this.car_flag.value == 1 ? this.vehicleType : null,
            car_flag: this.car_flag.value
          })
          .then(() => {
            this.getStep(20);
          });
      }
      this.getStep(20);
    },
    showProfileCar(param) {
      this.car_flag = param;
    },
    showProfileVehicleType(param) {
      this.vehicleType = param;
    },
    tutorialGolfMember() {
      if (this.golfMember) {
        this.$store
          .dispatch("editProfile/updateProfile", {
            step: 20,
            has_golf_membership: this.golfMember.value,
            membership_course:
              this.golfMember.value == 1 ? this.golfCourse : null
          })
          .then(() => {
            this.getStep(21);
          });
      }
      this.getStep(21);
    },

    nextTutorialNumberOfRound() {
      this.getStep(16);
    },

    tutorialNumberOfRound(event) {
      this.$store
        .dispatch("editProfile/updateProfile", {
          step: 15,
          golf_frequency_id: event.around
        })
        .then(() => {
          this.getStep(16);
        });
    },

    nextTutorialTobacco() {
      this.getStep(17);
    },

    tutorialTobacco(event) {
      this.$store
        .dispatch("editProfile/updateProfile", {
          step: 16,
          cigarette_id: event.cigarette
        })
        .then(() => {
          this.getStep(17);
        });
    },

    nextTutorialSake() {
      this.getStep(18);
    },

    tutorialSake(event) {
      this.$store
        .dispatch("editProfile/updateProfile", {
          step: 16,
          alcohol_id: event.alcohol
        })
        .then(() => {
          this.getStep(18);
        });
    },

    nextTutorialAnnualIncome() {
      this.getStep(19);
    },

    tutorialAnnualIncome(event) {
      this.$store
        .dispatch("editProfile/updateProfile", {
          step: 16,
          annual_income_id: event.income
        })
        .then(() => {
          this.getStep(19);
        });
    },
    showMember(param) {
      this.golfMember = param;
    },
    showGolfCourse(param) {
      this.golfCourse = param;
    },
    tutorialDating() {
      if (this.datingStatus) {
        this.$store
          .dispatch("editProfile/updateProfile", {
            step: 21,
            relationship_id: this.datingStatus.relationship_id
          })
          .then(() => {
            this.getStep(22);
            this.headerTitle.isShowPage = false;
          });
      }
      this.getStep(22);
      this.$store.dispatch("editProfile/updateProfile", {
        step: 22
      });
    },
    showDatingStatus(param) {
      this.datingStatus = param;
    },
    tutorialComplete() {
      localStorage.removeItem("step");
      this.$router.push({
        name: "Home",
        params: {
          showPopup: "show"
        }
      });
    },

    choosePrefecture(event) {
      if (this.prefecture != event) {
        this.address = null;
      }
      this.prefecture = event;
      this.residencesState = this.commonTutorials.residences.filter(
        residence =>
          residence.prefecture_id ==
          this.commonTutorials.prefectures[event].prefecture_id
      );
    },
    chooseResidence(event) {
      this.address = event;
      for (let area = 0; area < this.commonTutorials.areas.length; area++) {
        if (
          this.commonTutorials.residences[this.address].area_id ==
          this.commonTutorials.areas[area].area_id
        ) {
          this.residenceAreaName = this.commonTutorials.areas[area].name;
        }
      }
    },

    check(value) {
      value += "-";
      // console.log(value);
      this.postCode = value;
    },

    handleBlurPostCode() {
      this.postCode = this.postCode?.trim();
      if (
        this.postCode.length == 7 &&
        Number.isInteger(Number(this.postCode)) &&
        !this.postCode.includes(" ")
      ) {
        let postCodeStr = String(this.postCode);
        this.postCode =
          postCodeStr.substr(0, 3) + "-" + postCodeStr.substr(3, 4);
      }
    },

    filterPostCode() {
      let postCode = this.postCode;
      if (this.postCode) {
        postCode = this.postCode.replace("-", "");
        if (postCode != this.postCodeOld) {
          this.areaDetail = null;
          this.postCodeOld = postCode;
        }
      }
      const postal_code = require("japan-postal-code");
      const self = this;
      postal_code.get(postCode, async function(address) {
        self.userDetail.prefecture = address.prefecture;
        self.userDetail.residence = address.city;
        self.area = address.area;
        const params = {
          prefecture_name: address.prefecture,
          city_name: address.city,
          area_name: address.area
        };

        await self.$store
          .dispatch("common/getListArea", params)
          .then(res => {
            self.listAreaDetail = res.data;
          })
          .catch(error => {
            console.log(error);
          });
      });
    },

    checkPostCode() {
      this.userDetail.prefecture = null;
      this.userDetail.residence = null;
      this.area = null;
      this.areaDetail = null;
      this.listAreaDetail = [];
      if (
        (event.which >= 96 && event.which <= 105) ||
        (event.which >= 48 && event.which <= 57)
      ) {
        if (String(this.postCode)?.length == 3) {
          if (
            event.which != 8 &&
            event.which != 46 &&
            !this.postCode.includes(" ")
          ) {
            this.check(this.postCode);
          }
        } else if (
          this.postCode.length > 3 &&
          !this.postCode.includes("-") &&
          !this.postCode.includes(" ")
        ) {
          const postCodeStr = this.postCode;
          this.postCode =
            postCodeStr.substr(0, 3) +
            "-" +
            postCodeStr.substr(3, this.postCode.length - 3);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/men/editProfile.scss";
@import "~@/assets/scss/_fontFamily.scss";
#car_model,
#golf_course {
  box-shadow: none;
  outline: none;
}
.place-holder {
  color: #9c9c9c;
  top: 54px;
  left: 24px;
  right: 18px;
  font-size: 14px;
  font-weight: normal;
}
.content-scroll {
  // overflow-y: scroll;
  overflow-x: hidden;
  // height: calc(100vh - 108px);
  padding-top: 50px;
  padding-bottom: 50px;
}
.add-friend {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  z-index: 1000;
}
@media screen and (max-width: 360px) {
  .place-holder {
    font-size: 13px;
    font-weight: normal;
  }
}

@media screen and (min-width: 1200px) {
  .place-holder {
    top: 60px;
    left: 30px;
    font-size: 15px;
    font-weight: normal;
  }
  .content-scroll {
    padding-top: 60px;
    // height: calc(100vh - 152px);
  }
}
.modal-logout-title {
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.btn-logout {
  width: 155px;
  height: 45px;
  color: #ffffff;
  background-color: #464d77;
  font-size: 16px;
  border-radius: 5px;
}
.btn-cancel-logout {
  width: 155px;
  height: 45px;
  color: #000000;
  background-color: #e4e4e4;
  font-size: 16px;
  border-radius: 5px;
  margin-right: 10px;
}
</style>

<style lang="scss" scoped>
#edit-profile {
  .border-input {
    border: 1px solid #464d77 !important;
    background-color: #464d77 !important;
    color: #ffffff !important;
    max-width: 100px;
  }
  /deep/.edit-profile-vue-cropper {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 100;
    background-color: #fff;
    max-width: 1080px;
    .upload-example-cropper {
      height: calc(100% - 90px);
    }
    .btn-cancel {
      background-color: #dbdbdb;
      border-radius: 5px;
      height: 45px;
      width: 163px;
      margin-right: 11px;
      .btn {
        width: 100%;
        height: 100%;
      }
    }
    .btn-success {
      background-color: #464d77;
      border-radius: 5px;
      height: 45px;
      width: 163px;
      .btn {
        color: #fff;
        width: 100%;
        height: 100%;
      }
    }
    .btn-option {
      background-color: white;
      position: relative;
      z-index: 10;
      padding: 20px 0;
    }
  }
}

@media screen and (min-width: 1080px) {
  #edit-profile {
    /deep/.edit-profile-vue-cropper {
      top: 0;
      .upload-example-cropper {
        height: calc(100% - 173px);
      }
      .btn-cancel {
        height: 55px;
        width: 210px;
        margin-right: 20px;
        font-size: 20px;
      }
      .btn-success {
        height: 55px;
        width: 210px;
        font-size: 20px;
      }
      .btn-option {
        padding: 35px 0;
      }
    }
  }
}
</style>
